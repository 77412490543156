import axios from 'axios';

const getCurrencyInfo = async (countryCode) => {
  try {
    const response = await axios.get(`https://api.exchangerate-api.com/v4/latest/${countryCode}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching currency info:', error);
  }
};

export default getCurrencyInfo;
